<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language mr-1 mt-0 mb-0"
    :class="{'landing-page': isLandingPage}"
    right
  >
    <template #button-content>
      <img
        :src="require('@/assets/images/app-icons/language-94.png')"
        class="mr-50"
        :class="{
          'profile-img-height language_text_icon': !isLandingPage, 
          'd-none': isLandingPage && !isMobileView
        }"
        :alt="currentLocale.locale"
      />
      <span 
        class="text-uppercase language_text" 
        :class="{
          'd-none': !isLandingPage,
          'd-inline-block text-capitalize': isMobileView,
        }"
      >
        {{ $t('Language') }}
      </span>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="setLocale(localeObj.locale)"
      class="text-uppercase"
    >
      <!-- <b-img
        :src="localeObj.img"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
      /> -->
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'
import eventBus from '@/libs/event-bus'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  data() {
    return {
      locales: [
        {
          locale: 'en',
          // eslint-disable-next-line global-require
          img: require('@/assets/images/flags/en.png'),
          name: 'English',
        },
        {
          locale: 'hi',
          // eslint-disable-next-line global-require
          img: require('@/assets/images/flags/hi.png'),
          name: 'Hindi',
        },
        {
          locale: 'ar',
          // eslint-disable-next-line global-require
          img: require('@/assets/images/flags/ar.png'),
          name: 'Arabic',
        },
        {
          locale: 'de',
          // eslint-disable-next-line global-require
          img: require('@/assets/images/flags/de.png'),
          name: 'German',
        },
        {
          locale: 'es',
          // eslint-disable-next-line global-require
          img: require('@/assets/images/flags/es.png'),
          name: 'Spanish',
        },
        {
          locale: 'fr',
          // eslint-disable-next-line global-require
          img: require('@/assets/images/flags/fr.png'),
          name: 'French',
        },
        {
          locale: 'ru',
          // eslint-disable-next-line global-require
          img: require('@/assets/images/flags/ru.png'),
          name: 'Russian',
        },
        {
          locale: 'uz',
          // eslint-disable-next-line global-require
          img: require('@/assets/images/flags/uz.png'),
          name: 'Uzbek',
        },
      ],
    }
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
    isLandingPage: {
      get(){
        const allowedRoutes = [
          'landing-page',
          'user-landingpage',
          'landing-shop-page',
          'landing-provider-page',
          'blog',
          'knowledge-base',
          'faq',
          'contact-us',
          'about-us',
          'privacy-policy',
          'terms-and-condition',
        ]
        return allowedRoutes.some(r => r == this.$route.name)
      }
    },
    isMobileView: {
      get(){
        if(this.isLandingPage) return false;
        return this.$store.getters['app/windowSize'] <= 767
      }
    },
  },
  mounted() {
    eventBus.$on('set-language-locale', locale => {
      this.setLocaleLang(this.locales.find(x => x.locale === locale) ? locale : 'en')
    })
  },
  destroyed() {
    eventBus.$off('set-language-locale')
  },
  methods: {
    setLocaleLang(locale) {
      window.SuperApp.helper.changeTranslation(this, locale)
    },
    setLocale(locale) {
      window.SuperApp.getters.setLang(locale)
      this.setLocaleLang(locale)
    },
  },
}
</script>

<style>
@media only screen and (max-width: 767px) {
  #language-selection-dropdown ul.dropdown-menu.dropdown-menu-right a {
    padding: 0.65rem 1.28rem !important;
  }

  .dropdown-language a.dropdown-toggle {
    padding: 0 !important;
  }

  #language-selection-dropdown ul.dropdown-menu {
    top: unset !important;
    bottom: 0;
  }
}
</style>
<style scoped>
.profile-img-height{
	width: 32px !important;
	height: 32px !important;
}
@media only screen and (max-width: 767px) {
  .profile-img-height{
    width: 16px !important;
    height: 16px !important;
  }
}
</style>
