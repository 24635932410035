<template>
  <div :class="{ 'footer-tra': trasparentFooter }" class="footer-block">
    <b-row class="d-flex justify-content-between align-items-start">
      <b-col class="footer-widget" cols="12" order-md="2" md="3">
        <div class="widget-label">
          <li>
            {{ $t('Get to Know Us') }}
          </li>
        </div>
        <div class="widget-links">
          <ul>
            <li>
              <b-link :to="{
                path: '/about-us',
              }">
              <span class="content-width">{{ $t('About Us') }}</span>
              </b-link>
            </li>
            <li @click="goToHowItWorks">
              <span class="content-width">{{ $t('How it works') }}</span>
            </li>
            <li>
              <b-link :to="{
                path: '/provider',
              }">
                <span class="content-width">{{ $t('Partner with us') }}</span>
              </b-link>
            </li>
            <li>
              <b-link :to="{
                path: '/blog',
              }">
                <span class="content-width">{{ $t('Blog') }}</span>
              </b-link>
            </li>
          </ul>
        </div>
      </b-col>
      <b-col class="footer-widget" cols="12" order-md="3" md="3">
        <div class="widget-label">
          <li>
            {{ $t('Support 24/7') }}
          </li>
        </div>
        <div class="widget-links">
          <ul>
            <li>
              <b-link :to="{path: '/contact-us'}">
                {{ $t('Contact Us') }}
              </b-link>
            </li>
            <li>
              <b-link :to="{ path: '/faq'}">
                {{ $t('FAQs') }}
              </b-link>
            </li>
            <li>
              <b-link :to="{path: '/knowledge-base'}">
                {{ $t('Knowledge Base') }}
              </b-link>
            </li>
            <li>
              <b-link class="mt-2 mt-md-0 ml-0" href="https://play.google.com/store/apps/details?id=com.momitnow.twa" target="_blank">
                <b-img :src="require('@/assets/images/landing/google-play-store.png')" width="165" fluid />
              </b-link>
            </li>
          </ul>
        </div>
      </b-col>
      <b-col class="footer-widget border-rounded" cols="12" order-md="1" md="5">
        <div class="mb-2">
          <b-img fluid class="footer-logo" :src="require('@/assets/images/app-images/mom_r_wide.svg')" />
        </div>
        <div>
          <p class="logo-bottom">
            At MOM App, our journey is defined by innovation, simplicity, 
            and growth. We empower businesses with cutting-edge AI 
            solutions, revolutionizing the way commerce and services 
            operate in the digital age.
          </p>
        </div>
        <div class="social-icons">
          <b-link target="_blank" href="https://www.youtube.com/channel/UC227OBXYRlh-4-Bt4M3QONQ">
            <img :src="require('@/assets/images/icons/social/icons8-youtube.svg')">
          </b-link>
          <b-link target="_blank" href="https://www.facebook.com/MarketplaceOfMarketplaces">
            <img :src="require('@/assets/images/icons/social/icons8-facebook.svg')">
          </b-link>
          <b-link target="_blank" href="https://www.instagram.com/marketplace_of_marketplaces/">
            <img :src="require('@/assets/images/icons/social/icons8-instagram.svg')">
          </b-link>
          <b-link target="_blank" href="https://twitter.com/MarketingMOM5">
            <img :src="require('@/assets/images/icons/social/icons8-twitter.png')">
          </b-link>
          <b-link target="_blank" href="https://in.pinterest.com/MarketplaceOfMarketplace/_created/">
            <img :src="require('@/assets/images/icons/social/icons8-pinterest.svg')">
          </b-link>
          <b-link target="_blank" href="https://www.linkedin.com/company/momitnow/">
            <img :src="require('@/assets/images/icons/social/icons8-linkedin.svg')">
          </b-link>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AppFooter from '@core/layouts/components/AppFooter.vue'

export default {
  components: {
    AppFooter,
  },
  props: {
    trasparentFooter: {
      type: Boolean,
      default: false,
      required: false,
    }
  },
  mounted() {
    if (window.localStorage.getItem('scrollToHowItWorks') === '1') {
      this.scrollToHowItWorks()
      window.localStorage.removeItem('scrollToHowItWorks')
    }
  },
  methods: {
    // toggleChat() {
    //   if (window.Tawk_API) {
    //     window.Tawk_API.toggle()
    //   }
    // },
    scrollToHowItWorks() {
      setTimeout(() => {
        if (document.querySelector('.top-section-2')) {
          document.querySelector('.top-section-2').scrollIntoView({
            behavior: 'smooth',
          })
        }
        window.localStorage.removeItem('scrollToHowItWorks')
      }, 500)
    },
    goToHowItWorks() {
      if (window.location.pathname !== '/') {
        window.localStorage.setItem('scrollToHowItWorks', '1')
        this.$router.push({
          path: '/',
        })
      } else {
        this.scrollToHowItWorks()
      }
    },
  },
}
</script>

<style lang="scss">
.footer-block{
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
}
.footer-widget ul{
  list-style: none;
  padding-left: 0;
  li{
    margin-left: 0;
    color: #000;
    cursor: pointer;
    a{ 
      color: #000
    }
  }
}

.widget-links ul, .widget-label li{
  list-style: none;
}
.footer-logo{
  max-width: 25rem;
}
.social-icons{
  margin-top: 15px;
  a{
    margin-right: 7px;
    img{
      width: 32px;
    }
  }
}
.footer-block{
  text-align: center;
  padding: 0.4rem 3rem;
  padding-bottom: 0;
  .footer-widget{
    padding: 5px;
  }
  .widget-label{
    text-transform: uppercase;
    font-size: 1.4rem;
  }
  .footer-widget ul{
    li{
      font-size: 1.4rem;
    }
  }
  .logo-bottom{
    font-size: 1.4rem;
  }
  .content-width{
    width: max-content;
  }
}
.footer-block{
  text-align: center;
  padding: 0.4rem 3rem;
  padding-bottom: 0;
  .footer-widget{
    padding: 5px;
  }
  .widget-label{
    text-transform: uppercase;
    color: #847E7E;
    font-size: 1.4rem;
  }
  .footer-widget ul{
    li{
      font-size: 1.4rem;
      color: #000;
      a{ 
        color: #000
      }
    }
  }
  .logo-bottom{
    font-size: 1.4rem;
  }
  .content-width{
    background-color: rbga(0,0,0,0.2);
    width: max-content;
  }
}
.footer-tra{
  .footer-widget ul{
    li{
      color: #FFF;
      a{ 
        color: #FFF
      }
    }
  }
  .widget-label{
    color: #dad4d4;
  }
  .footer-widget ul{
    li{
      color: #FFF;
      a{ 
        color: #ffffff
      }
    }
  }
  .content-width{
    background-color: rbga(0,0,0,0.2);
  }
}
@media screen and (max-width: 767px) {
  .footer-logo{
    max-width: 100%;
  }
  .footer-tra{
    .widget-label{
      font-size: 1rem;
    }
    .footer-widget ul{
      li{
        font-size: 1rem;
      }
    }
    .logo-bottom{
      font-size: 1rem;
    }
  }
}
</style>
